.get-in-touch-container{
    margin-bottom: 8em;
    height: auto;
}
.submitButton{
    width: 100%;
    height: 2.5em;
}
.form-group {
    margin-bottom: 1rem; /* Adjust spacing between form elements */
}
.submitButton {
    margin-top: 0.5rem; /* Space above the submit button */
}



.map-container {

    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; /* Aspect ratio of 16:9 */
}

.map-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 170%;
    border: 0;
}

@media (max-width: 768px) {
    .map-iframe {
        width: 100%; /* Set width to 400px on small screens */
        height: 100%; /* Height will adjust automatically */
    }
    .map-container {
        margin-top: 2em;
    }
    .get-in-touch-form-div{
        padding: 1.5em;
    }
    .mobile-view-class{
        
    }
}
/* ------------------------------------------------------------------------------------------------------==== */
.faq-container{
    margin-top: 4em;
    margin-bottom: 4em;
}
.faq-item{
    margin-top: 1em;
}
.faq-heading{
    font-weight: 600;
    font-family: "Oswald",sans-serif;
}
.faq-question{
    font-weight: 600;
    font-family: "Oswald",sans-serif;
    margin-top: 2.5em;
}
.faq-answer{
    font-family: sans-serif;
    margin-top: 1.5em;
}

@media (max-width: 768px){
    .faq-container{
        padding: 1.5em;
    }
}