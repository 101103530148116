.watsapp-qr-code-image{
    width: 20vw;
}
.scan-qr-div {
    padding: 40px 0;
    background-color: #f9f9f9; /* Optional: Add background color if desired */
    margin-top:-5%;
}

.watsapp-qr-code-image {
    max-width: 100%; /* Ensure the image is responsive */
    height: auto;    /* Maintain aspect ratio */
}
@media (max-width: 768px){
    .watsapp-qr-code-image {
        width: 70%; /* Ensure the image is responsive */
        height: auto;    /* Maintain aspect ratio */
    }
}