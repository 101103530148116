.unique-container{
    display: flex;
    justify-content: center;
    
}

.unique-terms-heading h1{
    color: black(244, 236, 236);
    margin: top;
    padding: 0;
    font-family: 'Oswald', 'Segoe UI';
    font-weight:1000;
    font-size: 2rem; 
    justify-content: center;
}

.unique-terms-content1,.terms content2{
    color: black(244, 236, 236);
    margin: top;
    padding: 0;
    font-family: 'Oswald', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:350;
    font-size: 1rem; 
    Text-align:left;
    
}
 
 .unique-terms-content2 h1{
    text-align:left;
 }
 
 .unique-terms-content3{
    color: black(244, 236, 236);
    margin: top;
    padding: 0;
    font-family: 'Oswald', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:350;
    font-size: 1rem; 
    Text-align:left;
 }
 
 .unique-term-content4{
    color: black(244, 236, 236);
    margin: top;
    padding: 0;
    font-family: 'Oswald', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:350;
    font-size: 1rem; 
    Text-align:left;
    
}

.unique-terms-content5{
    color: black(244, 236, 236);
    margin: top;
    padding: 0;
    font-family: 'Oswald', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:350;
    font-size: 1rem; 
    Text-align:left;

}

.unique-terms-content6{
    color: black(244, 236, 236);
    margin: top;
    padding: 0;
    font-family: 'Oswald', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:350;
    font-size: 1rem; 
    Text-align:left;
}


.unique-terms-content7{
    color: black(244, 236, 236);
    margin: top;
    padding: 0;
    font-family: 'Oswald', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:350;
    font-size: 1rem;
    Text-align:left;
}

.unique-terms-content8{
    color: black(244, 236, 236);
    margin: top;
    padding: 0;
    font-family: 'Oswald', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:350;
    font-size: 1rem; 
    Text-align:left;
}
.unique-terms-content9{
    color: black(244, 236, 236);
    margin: top;
    padding: 0;
    font-family: 'Oswald', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:350;
    font-size: 1rem; 
    Text-align:left;
}



