/* banner video */
.visible-on-small-screens{
    display: none;
}
.video-container {
    margin-top: 0;
    position: relative; 
    width: 100%;
    height: 100vh; /* Adjust this as needed */
    overflow: hidden;
    opacity: 1;

}
.text-overlay-black-bg{
    width: 100vw;
    height: 100vh;
    z-index: 1;
    background-color: #000000;

}
.fullscreen-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    object-fit: cover; /* Cover the container without distortion */
    margin: 0;
    padding: 0;
    z-index: 2; /* Place the video behind the text */
    opacity: 1;
}

.text-overlay {
    
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center text horizontally and vertically */
    color: white; /* Ensure text is readable against the video */
    text-align: center; /* Center-align the text */
    z-index: 3; /* Place text above the video */
    
}
/* Style text elements */
.text-overlay h1 {
    color: #333333;
    margin: 0;
    padding: 10px 0; /* Space between lines of text */
    font-size: 2.5rem; /* Adjust size as needed */
    line-height: 1.2; /* Improve readability */
}

.text-overlay h3 {
    margin: 0;
    padding: 10px 0; /* Space around text */
    font-size: 1.5rem; /* Adjust size as needed */
    line-height: 1.4; /* Improve readability */
}

.banner-heading{
    font-family: "Oswald", sans-serif;
    font-weight: 600;
}
.heading-1 ,.heading-2{
    font-size: 3.5em;
    line-height: 1em;
    color: white;
}
.heading-3{
    font-size: 2em;
    line-height: 1em;
    color: white;
}
.learn-more-button{
    
    width: 10em;
    height: 2.5em;
    border-color:white ;
    color: white;
    background-color: transparent;
    font-family: sans-serif;
  }

  .learn-more-button:hover {
    transform: scale(1.05); /* Zoom effect */
    box-shadow: 0 0.25em 0.5em rgba(0,0,0,0.2); /* Use em units for shadow */
    background-color: #ffffff;
    color: #000000;
}

/* for mobile */
@media (max-width: 768px){
    .visible-on-large-screens{
        display: none;
    }
    .visible-on-small-screens{
        display: block;
    }
    .heading-1 ,.heading-2{
        font-size: 2em;
        line-height: 1em;
        color: white;
    }
    .heading-3{
        font-size: 1em;
        line-height: 1em;
        color: white;
    }
    .learn-more-button{
        
        width: 7em;
        height: auto;
        border-color:white ;
        color: white;
        background-color: transparent;
      }
}

/* -------------------------------------------------------------------------------------------------------------- */

/* marquee */
.marquee-container {
    width: 100%;
    height: 5em; /* Adjust height as needed */
    overflow: hidden; /* Hide the overflow content */
    position: relative;
    background-color: #000000; /* Optional: Background color for contrast */
    color: #ffffff;
    font-family: 'Oswald',sans-serif;
    font-size: 1.2em;
}

.marquee-wrapper {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
}

.marquee {
    margin-top:2em;
    display: flex;
    white-space: nowrap;
    animation: marquee 30s linear infinite; /* Adjust timing as needed for slower scrolling */
}

.marquee p {
    margin: 0;
    padding-right: 2rem; /* Space between items, adjust as needed */
}

/* Ensure that the text wraps seamlessly */
.marquee-wrapper {
    display: flex;
    flex-direction: row;
}

@keyframes marquee {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-50%);
    }
}

/* ------------------------------------------------------------------------------------------------------------- */
.services-overview-container{
    position: relative;
    width: 100vw;
    height: 100vh;
}
.services-overview-image{
    width: 100vw;
    height: inherit;
    opacity: 0.75;
}
.services-heading{
    font-family: "Oswald", sans-serif;
    font-weight: 600;
    font-size: 2em;
    line-height: 1em;
}
.services-para{
    font-family: sans-serif;
}
.services-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Black background with 50% opacity */
    color: #ffffff; /* White text color for contrast */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center the content vertically */
    align-items: center; /* Center the content horizontally */
    padding: 2rem; /* Adjust padding as needed */
    box-sizing: border-box; /* Ensure padding doesn't affect width */
}

@media (max-width: 768px){
    .services-heading{
        font-size: 1.5em;
    }
}

/* --------------------------------------------------------------------------------------------------------------- */
/* Container for the cards */
.service-offered-container {
    margin-top: 3em;
    width: 100vw;
    height: auto;
    margin-bottom: 3em;
    
}

/* Heading Style */
.our-services-heading {
    font-family: "Oswald", sans-serif;
    margin-bottom: 1em;
    text-align: center; /* Center align the heading */
}

/* Ensure all cards are of the same size */
.card {
    border: 1px solid #000000;
    border-radius: 0.25em; /* Use relative units for border-radius */
    padding: 1.25em; /* Use em units for padding */
    margin-bottom: 1.25em; /* Use em units for margin */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Align content evenly */
    min-height:15em;
}

/* Card title styling */
.card-title {
    font-family: "Oswald", sans-serif;
    font-size: 1.125em; /* Use em units for font size */
    font-weight: bold;
    margin-bottom: 1em; /* Use em units for margin */
}

/* Adjust card body padding */
.card-body {
    padding: 0.9375em; /* Use em units for padding */
    flex-grow: 1; /* Make sure card body takes up remaining space */
}

/* Card text styling */
.card-text {
    font-size: 0.875em; /* Use em units for font size */
}

/* Icon styling */
.fa-icon {
    font-size: 2em; /* Assuming you want a larger size, em is appropriate here */
    margin-bottom: 0.5em; /* Use em units for margin */
}

/* Hover effect */
.card:hover {
    transform: scale(1.05); /* Zoom effect */
    box-shadow: 0 0.25em 0.5em rgba(0,0,0,0.2); /* Use em units for shadow */
    background-color: #000000;
    color: #ffffff;
}

/* Center align cards in their columns on small screens */
@media (max-width: 767px) {
    .row {
        display: flex;
        flex-direction: column;
        align-items: center; /* Center align items in the column */
    }

    .col-md-4 {
        display: flex;
        justify-content: center; /* Center align the card within the column */
        margin-bottom: 1.25em; /* Ensure there’s space between rows */
    }
    
    .card {
        width: 100%; /* Make sure the card takes full width of its container */
        max-width: 25em; /* Optionally set a max-width for cards */
    }
}

/* ----------------------------------------------------------------------------------------------------------- */