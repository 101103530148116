.co-founder-container {
    padding: 40px 0;
}


.co-founder-container .row {
    align-items: center; /* Vertically center the content */
}
.co-founder-image{
    width: 70%;
    height: 20%;
}
.co-founder-image {
    max-width: 100%; /* Ensure the image is responsive */
    height: auto;    /* Maintain aspect ratio */
}

@media (max-width: 768px){
    .co-founder-image{
        margin-bottom: 3em;
    }
}