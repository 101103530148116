/* Unique Landing Banner Styles */
.unique-landing-banner-wrapper {
    position: relative;
    width: 100vw;
    height: 60vh;
}

.unique-landing-banner-image {
    width: 100vw;
    height: 100%;
    opacity: 0.75;
    object-fit: cover; /* Ensure image covers the area properly */
}

.unique-landing-banner-heading {
    font-family: "Oswald", sans-serif;
    font-weight: 600;
    font-size: 4.5em;
    line-height: 1em;
}

.unique-landing-banner-tagline {
    font-family: "Oswald", sans-serif;
    font-weight: 600;
    font-size: 1.5em;
    line-height: 2.5em;
}

.unique-landing-banner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Black background with 50% opacity */
    color: #ffffff; /* White text color for contrast */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center the content vertically */
    align-items: center; /* Center the content horizontally */
    padding: 2rem; /* Adjust padding as needed */
    box-sizing: border-box; /* Ensure padding doesn't affect width */
}
.unique-links-para {
    line-height: 0.5; /* Adjust this value to reduce or increase line height */
    margin: 0; /* Remove default margin if necessary */
    padding: 0; /* Remove default padding if necessary */
}
.fa-home-unique{
    font-size: 1.5em;
}
.unique-link-banner{
    text-decoration: none;
    color: white;
}

@media (max-width: 768px) {
    .unique-landing-banner-heading {
        font-size: 3.2em;
    }
    .unique-landing-banner-tagline {
        font-size: 1.3em;
    }
    .unique-links-para{
        line-height: 0.8em;
        font-size: 0.8em;
    }
}

/* -------------------------------------------------------------------------------------------------------- */
/* Image div with text overlay */

.overlay-div-container {
    position: relative;
    width: 100vw;
    height: 100vh;
}

.overlay-div-image {
    width: 100vw;
    height: 100vh;
    object-fit: cover; /* Ensure the image covers the container without distortion */
    opacity: 0.75;
}

.overlay-div-heading {
    font-family: "Oswald", sans-serif;
    font-weight: 600;
    line-height: 1em;
}
.overlay-div-heading-1{
    font-size: 2.5em;
}
.overlay-div-heading-2{
    font-size: 1.5em;
}

.overlay-div-para {
    font-family: sans-serif;
}

.overlay-div-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Black background with 50% opacity */
    color: #ffffff; /* White text color for contrast */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center the content vertically */
    align-items: center; /* Center the content horizontally */
    padding: 2rem; /* Adjust padding as needed */
    box-sizing: border-box; /* Ensure padding doesn't affect width */
}

@media (max-width: 768px) {
    
    .overlay-div-heading-1{
        font-size: 1.5em;
    }
    .overlay-div-heading-2{
        font-size: 1em;
    }
    
    .overlay-div-para {
        font-family: sans-serif;
        font-size: 0.9em;
    }
    
}
/* ---------------------------------------------------------------------------------------------------------- */
/* white background div with text div */
.white-bg-div-with-text-container{
    margin-top:4em;
    margin-bottom:4em;
    
}
.white-bg-heading1{
    font-weight: 600;
    font-size: 2.7em;
    font-family: "Oswald",sans-serif;
}
.white-bg-heading2{
    margin-top: 0.5em;
    font-family: "Oswald",sans-serif;
}
.white-bg-para-div{
    margin-top: 0.5em;
}
.white-bg-para{
    font-family: sans-serif;
}
@media (max-width: 768px){
    .white-bg-div-with-text-container{
        padding: 1.5em;
    }
    .white-bg-heading1{
        font-size: 2.2em;
    }
}
/* --------------------------------------------------------------------------------------------------------- */