.privacy-policy-container {
    display: flex;
    justify-content: center;
    font-weight:500;
    font-size:2
}

.privacyPolicy.heading h1 {
    color: black(244, 236, 236);
    margin: top;
    padding: 0;
    font-family: 'Oswald', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:500;
    font-size: 1rem; 
    justify-content: center;
}

.privacyPolicy-content1,.privacyPolicy-content2{
    color: black(244, 236, 236);
    margin: top;
    padding: 0;
    font-family: 'Oswald', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:350;
    font-size: 1rem; 
    Text-align:left;
    
}
 .privacyPolicy-content2 h1{
    text-align:left;
 }
.privacyPolicy-content3{
    color: black(244, 236, 236);
    margin: top;
    padding: 0;
    font-family: 'Oswald', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:350;
    font-size: 1rem; 
    Text-align:left;

}
.privacyPolicy-content4{
    color: black(244, 236, 236);
    margin: top;
    padding: 0;
    font-family: 'Oswald', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:350;
    font-size: 1rem; 
    Text-align:left;
    
}
.privacyPolicy-content5{
    color: black(244, 236, 236);
    margin: top;
    padding: 0;
    font-family: 'Oswald', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:350;
    font-size: 1rem; 
    Text-align:left;

}
.privacyPolicy-content6{
    color: black(244, 236, 236);
    margin: top;
    padding: 0;
    font-family: 'Oswald', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:350;
    font-size: 1rem; 
    Text-align:left;
}

.privacyPolicy-content7{
    color: black(244, 236, 236);
    margin: top;
    padding: 0;
    font-family: 'Oswald', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:500;
    font-size: 1rem; /* Adjust the size if needed */ /* Center text inside the h1 */
    Text-align:left;
}
