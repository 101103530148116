.about-us-second-div{
    margin-top: 0.5em;
    background-color:white;
}
.about-us-second-div-para-heading{
    font-size: 2.6em;
    font-weight: 600;
    font-family: "Oswald", sans-serif;
}
.about-us-second-div-para{
    font-size: 0.95em ;
    margin-top: 2em;
    font-family: sans-serif;
}
.contact-us-button{
    width: 10em;
    height: 2.6em;
    border-color:black ;
    color: black;
    background-color: #ffffff;
    font-family: sans-serif;
}
.about-us-second-div-text-part{
    margin-top: 4em;
}
/* -------------------------------------------------------------------------------------------------------------- */

.about-us-third-div-container {
   
    width: 100vw;
    margin-top: 2em;
    background-size: cover; /* Adjusts the size of the background image */
    background-position: center; /* Centers the background image */
    background-repeat: no-repeat;
}
.about-us-third-div-container {
    min-height: 100vh; /* Ensure the container takes full viewport height */
}

.about-us-card-landscape {
    /* Adjust card width to ensure it looks good */
    width: 100%; /* Or a specific width if needed */
    max-width: 500px; /* Set a max-width if you want to restrict the card size */
    color: #ffffff;
    filter: brightness(100%);
    border:solid 2px #ffffff;
    padding: 2em;
}
.about-us-card-title{
    font-family: "Oswald",sans-serif;
    font-weight: 600;
    font-size: 2em;
}
@media (max-width: 768px){
    .about-us-card-landscape{
        padding: 0.5em;
    }
}
/* ----------------------------------------------------------------------------------------------------------- */

