/* navbar */
header{
  margin-bottom: -1.1em;
}
.navbar{
    background-color:#000000 ;

}

  .logo-image{
    width: 12em;
    
}
.nav-item{
    margin-left: 1.1em;
}
.navbar .navbar-collapse {
    justify-content: flex-end; /* Aligns the links to the right */
}
.navbar-nav{
    font-size: 1.1em;
}



@media (min-width: 769px) and (max-width: 1024px) {
  .logo-image{
    width: 8.5em;
    
}
  .nav-item{
    margin-left: 0.6em;
    font-size: 0.8em;
  }
}
  @media (max-width: 768px) {

    .navbar{
      padding-right: 0%;
      padding-left: 0%;
  }
    .logo-image{
        width: 8em;
    }
    .navbar-collapse{
        margin-left: 0em;
    }
    .nav-item{
        margin-left: 0em;
    }
  }
  
  /* footer */
.footer-div{
    padding-top: 1em;
    background-color:#000000;
    text-decoration: None;
}
  .footer-div{
    color: white;
  }
  .no-underline {
    text-decoration: none; 
        color: inherit; 
}


/* --------------------------------------------------------------------------------------------------- */
