/* ScrollToTopButton.css */

.scroll-to-top {
    position: fixed;
    bottom: 20px; /* Distance from the bottom of the viewport */
    right: 20px;  /* Distance from the right of the viewport */
    background-color: black; /* Button color */
    color: white; /* Text color */
    border: none;
    border-radius: 50%;
    width: 50px; /* Button size */
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect */
    cursor: pointer;
    font-size: 24px; /* Icon size */
    z-index: 1000; /* Ensure it is above other elements */
    transition: background-color 0.3s ease;
  }
  
  .scroll-to-top:hover {
    background-color: #0056b3; /* Darker color on hover */
  }
  